import {useUserStore} from "~/stores/user"

export default defineNuxtRouteMiddleware(async (to, from) => {
    const userStore = useUserStore()

    // Ensure we have the correct geolocation data
    if (!userStore.isAuthenticated) {
        try {
            await useSession().getSession()
        } catch (e) {}
    }
})
